

import { ROUTE_BILLING } from "@/AuthenticatedAppRoutes";
import NavButton from "@components/layout/navigation/NavButton";
import { Divider, Stack, Typography } from "@mui/material";

type GoToPaymentsProps = {
}

const GoToPayments = ({ }: GoToPaymentsProps) => {

  return (
    <>

      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Typography color="text.secondary" variant="subtitle1">
            Payments
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            You can manage and view your payments
          </Typography>
        </Stack>

        <NavButton
          to={ROUTE_BILLING}
          title="Payments"
          label="Go to Payments"
        />
      </Stack>
      <Divider />
    </>
  );
};

export default GoToPayments;