import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";

export type getOAuthUrlParams = {
  email: string;
};


export const getOAuthUrl = async ({
  email
}: getOAuthUrlParams) => {
  const options = {
    method: "GET",
    timeout: 5 * 60 * 1000, // 5 minutes

    query: {
      email: email
    },
  };

  const response = await fetchOutreachService(
    `/oauth/perform/code`,
    options
  );

  return response;
};


type getOAuthUrlResponse = {
  oauthUrl: string;
};

export const useGetOAuthUrl = () =>
  useServiceCall<getOAuthUrlResponse, getOAuthUrlParams>({
    serviceCall: getOAuthUrl,
    errorMessage: "Failed to get OAuth URL, please try again",
  });
