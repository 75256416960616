
enum BYOBStage {
  CHOOSE_EMAIL_PROVIDER = "CHOOSE_EMAIL_PROVIDER",

  READ_GOOGLE_INSTRUCTIONS_IMAP = "READ_GOOGLE_INSTRUCTIONS_IMAP",
  READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH = "READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH",
  ACTUALLY_AUTHENTICATE_GOOGLE = "ACTUALLY_AUTHENTICATE_GOOGLE",
  
  READ_OUTLOOK_INSTRUCTIONS = "READ_OUTLOOK_INSTRUCTIONS",
  ACTUALLY_AUTHENTICATE_OUTLOOK = "ACTUALLY_AUTHENTICATE_OUTLOOK",

  READ_GODADDY_INSTRUCTIONS = "READ_GODADDY_INSTRUCTIONS",
  ACTUALLY_AUTHENTICATE_GODADDY = "ACTUALLY_AUTHENTICATE_GODADDY",
}

export { BYOBStage };
