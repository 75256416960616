import { Box, Button, Link, Typography } from "@mui/material";

import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { BYOBStage } from "./types";





interface InstructionsProps {
  setStage: (stage: BYOBStage) => void;
}

const OutlookInstructions = (
  { setStage }: InstructionsProps
) => {

  const handleReady = () => {
    setStage(BYOBStage.ACTUALLY_AUTHENTICATE_OUTLOOK);
  }

  return (
    <>
      <Typography variant="h6">
        2. Connect your Microsoft Office 365 Account to Kular
      </Typography>


      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingLeft: 2, marginTop: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            1.
          </Typography>
          <Typography variant="body1">
            Log in to <Link href="https://admin.microsoft.com/#/homepage" target="_blank" rel="noopener"><Typography component="span" fontWeight="bold">Microsoft Admin center</Typography></Link>.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            2.
          </Typography>
          <Typography variant="body1">
            Open <Link href="https://admin.microsoft.com/#/users" target="_blank" rel="noopener"><Typography component="span" fontWeight="bold">Active Users</Typography></Link>.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            3.
          </Typography>
          <Typography variant="body1">
            In the side window, click on <Typography component="span" fontWeight="bold">Mail</Typography> tab, and then on <Typography component="span" fontWeight="bold">Manage email apps</Typography>.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            4.
          </Typography>
          <Typography variant="body1">
            Check the <Typography component="span" fontWeight="bold">Authenticated SMTP</Typography> box and make sure <Typography component="span" fontWeight="bold">IMAP</Typography> is checked too.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            5.
          </Typography>
          <Typography variant="body1">
            Click <Typography component="span" fontWeight="bold">Save Changes</Typography>.
          </Typography>
        </Box>

      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReady}
        endIcon={<MicrosoftIcon />}
        sx={{
          marginTop: 2,
        }}
      >
        I'm Ready
      </Button>
    </>
  )
}

export default OutlookInstructions;