import {
  ROUTE_DASHBOARD,
  ROUTE_PROFILE
} from "@/AuthenticatedAppRoutes";
import { ArrowRightIcon, FAQIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Fade, Stack, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { getFullName } from "@utils/text";
import { Link } from "react-router-dom";
import Logo from "../Logo";

type PageHeaderProps = {};

const PageHeader = ({ }: PageHeaderProps) => {
  // Profile
  const { profile, isCreatingProfile } = useAppContext();

  // Profile full name
  const profileFullName = getFullName(profile?.firstName, profile?.lastName);

  // Sign out
  // const { signOut } = useAuthenticator();

  return profile ? (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "#fafafa",
          color: "primary.main",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            py: 1,
            lineHeight: 1,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
            <Fade in={true} timeout={2000}>
              <Box textAlign={"left"} ml={2} flexGrow={1}>
                <Link
                  to={ROUTE_DASHBOARD}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Box color={"primary.main"}>
                    <Logo height={10} />
                  </Box>
                </Link>
              </Box>
            </Fade>
            <Link
              to="https://www.kular.ai/guides-and-walkthroughs"
              style={{
                textDecoration: "none",
              }}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <FAQIcon fontSize="small" color="primary" />
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.875rem",
                  }}
                >
                  Help
                </Typography>
              </Stack>
            </Link>
            <LoadingButton
              loading={isCreatingProfile}
              component={Link}
              to={ROUTE_PROFILE}
              startIcon={
                <Avatar
                  alt={profileFullName || ""}
                  src={profile?.photoUrl || ""}
                  sx={{ width: 28, height: 28 }}
                />
              }
              endIcon={<ArrowRightIcon fontSize="small" color="primary" />}
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontSize: "0.875rem",
                borderColor: "#d0d0d0",
              }}
            >
              <Typography variant="subtitle2" color="text.primary">{profileFullName}</Typography>
            </LoadingButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  ) : null;
};

export default PageHeader;
