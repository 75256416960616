import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";


type getBringYourOwnEmailStatusParams = {}

export const getBringYourOwnEmailStatus = async ({
  
}: getBringYourOwnEmailStatusParams = {}) => {
  const options = {
    method: "GET",
    timeout: 5 * 60 * 1000, // 5 minutes
  };

  const response = await fetchOutreachService(
    `/users/sender_profiles/bring-your-own-email`,
    options
  );

  return response;
  
};


type BringYourOwnEmailStatus = {
  email: string;
  hedwigStatus: boolean;
};




export const useGetBringYourOwnEmailStatus = () =>
  useServiceCall<BringYourOwnEmailStatus[], getBringYourOwnEmailStatusParams>({
    serviceCall: getBringYourOwnEmailStatus,
  });
