// Campaigns Context

import {
  useGetCampaignByIdLazyQuery,
} from "@/graphql/generated";
import {
  CONTACTED_MEMBER_STATUSES,
  Campaign,
  CampaignChannel,
  CampaignMember,
  CampaignMemberStatus,
  Prospect,
  Template,
} from "@/models";
import { UseServiceOptions } from "@hooks/useServiceCall";
import { useDuplicateCampaign } from "@services/campaigns/duplicateCampaign";
import {
  PatchEmailCampaignParams,
  usePatchEmailCampaign,
} from "@services/campaigns/patchEmailCampaign";
import {
  PatchLinkedinCampaignParams,
  usePatchLinkedinCampaign,
} from "@services/campaigns/patchLinkedinCampaign";
import { useSearchProspectsByCampaign } from "@services/campaigns/searchProspectsByCampaign";
import { useGetFilteredMembers } from "@services/members/filterCampaignMembers";
import { UUID } from "@utils/text";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { normalizeGraphqlResult } from "../utils/graphql";
import { useAppContext } from "./AppContext";
import { useCampaignsContext } from "./CampaignsContext";

type CampaignContextProps = {
  campaign: Campaign | null;
  templates: Array<Template>;
  isLoading: boolean;
  editedTemplateId: string | null;
  pagedProspects: PagedProspects | null;
  isSearchingProspects: boolean;
  prospectsPage: number;
  isPatchingCampaign: boolean;
  targetingRequest?: string;
  totalContactedMembers?: number;
  totalScheduledMembers?: number;
  pagedContactedMembers?: Array<CampaignMember>;
  pagedScheduledMembers?: Array<CampaignMember>;
  isDuplicatingCampaign: boolean;
  patchCampaign: (
    patch: PatchEmailCampaignParams | PatchLinkedinCampaignParams,
    options?: UseServiceOptions
  ) => void;

  setProspectsPage: (page: number) => void;
  searchProspectsAndEstimate: () => void;
  setEditedTemplateId: (templateId: string | null) => void;
  fetchCampaign?: () => void;
  getContactedMembersPage: (page: number) => void;
  getScheduledMembersPage: (page: number) => void;
  duplicateCampaign: (campaignId: string, channel?: CampaignChannel) => void;
};

type PagedProspects = {
  [key: number]: Prospect[];
};

const initialValues: CampaignContextProps = {
  campaign: null,
  templates: [],
  isLoading: false,
  editedTemplateId: null,
  pagedProspects: null,
  isSearchingProspects: false,
  prospectsPage: 1,
  isPatchingCampaign: false,
  targetingRequest: "",
  totalContactedMembers: 0,
  totalScheduledMembers: 0,
  pagedContactedMembers: [],
  pagedScheduledMembers: [],
  isDuplicatingCampaign: false,
  patchCampaign: () => { },
  setProspectsPage: () => { },
  searchProspectsAndEstimate: () => { },
  setEditedTemplateId: () => { },
  fetchCampaign: () => { },
  getContactedMembersPage: () => { },
  getScheduledMembersPage: () => { },
  duplicateCampaign: () => { },
};

const CampaignContext = createContext<CampaignContextProps>(initialValues);
const PROSPECTS_PAGE_SIZE = 20;
const MEMBERS_PAGE_SIZE = 20;

export const CampaignProvider = ({
  campaignId,
  withProspects = false,
  campaign,
  children,
}: {
  campaignId: string;
  campaign?: Campaign;
  withProspects?: boolean;
  children: React.ReactNode;
}) => {
  const { profile } = useAppContext();
  const { fetchCampaigns } = useCampaignsContext();

  // Get Campaign
  const [
    getCampaignById,
    { data: campaignData, loading: isLoadingCampaign, refetch: fetchCampaign },
  ] = useGetCampaignByIdLazyQuery();

  // Fetch campaign
  const [_campaign, setCampaign] = useState<Campaign | null>(null);

  // Initialize campaign if passed in
  useEffect(() => {
    if (!campaign) return;

    setCampaign(campaign);
  }, [campaign]);

  useEffect(() => {
    if (!campaignId) return;

    getCampaignById({
      variables: {
        campaignId,
      },
    });
  }, [campaignId, getCampaignById]);

  useEffect(() => {
    if (!campaignData) return;
    if (campaign) return;

    const campaignResponse = normalizeGraphqlResult(campaignData);

    setCampaign(campaignResponse.campaign);
  }, [campaign, campaignData]);

  // Editted template
  const [editedTemplateId, setEditedTemplateId] = useState<string | null>(null);

  // Search prospects
  const [prospectsPage, setProspectsPage] = useState<number>(1);
  const [pagedProspects, setPagedProspects] = useState<PagedProspects | null>(
    null
  );

  const [
    searchProspects,
    { data: prospectsData, loading: isSearchingProspects },
  ] = useSearchProspectsByCampaign();

  // Update paged prospects
  useEffect(() => {
    if (!prospectsData?.items) return;

    setPagedProspects((prev) => {
      const newPagedProspects = {
        ...prev,
        [prospectsPage]: prospectsData.items,
      };

      return newPagedProspects;
    });
  }, [prospectsData?.items, prospectsPage]);

  // Targeting
  // - User facing
  const targetingRequest = _campaign?.targetingRequest;

  // Search
  const searchProspectsAndEstimate = () => {
    if (!withProspects) return;
    if (!_campaign?.id) return;

    searchProspects({
      campaignId: _campaign.id,
      page: prospectsPage,
      size: PROSPECTS_PAGE_SIZE,
    });
  };

  // Initial search
  useEffect(() => {
    searchProspectsAndEstimate();
  }, [
    prospectsPage, _campaign?.suggestedTargetingId,
  ]);

  // Templates
  const templates = useMemo(() => {
    return [
      ...(_campaign?.emailTemplates || []),
      ...(_campaign?.linkedinTemplates || []),
    ].filter(Boolean);
  }, [_campaign?.emailTemplates, _campaign?.linkedinTemplates]);

  // Patch campaign
  const [patchEmailCampaign, { loading: isPatchingEmailCampaign }] =
    usePatchEmailCampaign();
  const [patchLinkedinCampaign, { loading: isPatchingLinkedinCampaign }] =
    usePatchLinkedinCampaign();

  const patchCampaign = async (
    patch: PatchEmailCampaignParams | PatchLinkedinCampaignParams,
    options?: UseServiceOptions
  ) => {
    if (_campaign?.channel === CampaignChannel.EMAIL) {
      await patchEmailCampaign(patch, options);
    } else if (_campaign?.channel === CampaignChannel.LINKEDIN) {
      await patchLinkedinCampaign(patch, options);
    }
    fetchCampaign?.();
    fetchCampaigns?.();
  };

  const isPatchingCampaign = useMemo(() => {
    return isPatchingEmailCampaign || isPatchingLinkedinCampaign;
  }, [isPatchingEmailCampaign, isPatchingLinkedinCampaign]);

  // Paged Contacted members
  const [filterCampaignMembers] = useGetFilteredMembers();
  const [totalContactedMembers, setTotalContactedMembers] = useState<number>(0);
  const [totalScheduledMembers, setTotalScheduledMembers] = useState<number>(0);
  const [pagedContactedMembers, setPagedContactedMembers] = useState<
    Array<CampaignMember>
  >([]);
  const [pagedScheduledMembers, setPagedScheduledMembers] = useState<
    Array<CampaignMember>
  >([]);

  const getContactedMembersPage = async (page: number) => {
    const contactedMembers = await filterCampaignMembers({
      campaignIds: [campaignId as UUID],
      statuses: CONTACTED_MEMBER_STATUSES,
      offset: (page - 1) * MEMBERS_PAGE_SIZE,
      first: MEMBERS_PAGE_SIZE,
    });

    setTotalContactedMembers(contactedMembers?.total ?? 0);
    setPagedContactedMembers(contactedMembers?.items ?? []);
  };

  const getScheduledMembersPage = async (page: number) => {
    const scheduledMembers = await filterCampaignMembers({
      campaignIds: [campaignId as UUID],
      statuses: [CampaignMemberStatus.SCHEDULED_OUTREACH],
      offset: (page - 1) * MEMBERS_PAGE_SIZE,
      first: MEMBERS_PAGE_SIZE,
    });

    setTotalScheduledMembers(scheduledMembers?.total ?? 0);
    setPagedScheduledMembers(scheduledMembers?.items ?? []);
  };

  // useEffect(() => {
  //   if (!campaignId) return;

  //   getContactedMembersPage(1);
  //   getScheduledMembersPage(1);
  // }, [campaignId]);

  // Duplicate campaign
  const [
    _duplicateCampaign,
    { data: _duplicatedCampaign, loading: isDuplicatingCampaign },
  ] = useDuplicateCampaign();

  const duplicateCampaign = useCallback(
    async (campaignId: string, channel?: CampaignChannel) => {
      if (!profile?.lawyer?.id) return;
      if (!campaignId) return;

      await _duplicateCampaign({
        lawyerId: profile.lawyer.id,
        campaignId,
        channel,
      });
    },
    [campaignId, profile?.lawyer?.id]
  );

  return (
    <CampaignContext.Provider
      value={{
        campaign: _campaign,
        templates,
        isLoading: isLoadingCampaign,
        editedTemplateId,
        isSearchingProspects,
        pagedProspects,
        prospectsPage,
        isPatchingCampaign,
        targetingRequest,
        totalContactedMembers,
        totalScheduledMembers,
        pagedContactedMembers,
        pagedScheduledMembers,
        isDuplicatingCampaign,
        patchCampaign,
        setProspectsPage,
        searchProspectsAndEstimate,
        setEditedTemplateId,
        fetchCampaign,
        getContactedMembersPage,
        getScheduledMembersPage,
        duplicateCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaignContext = () => useContext(CampaignContext);
