import { TrackedEventName } from "@/third-party/tracking";
import { SignOutIcon } from "@components/icons";
import Tracked from "@components/Tracked";
import { useAppContext } from "@context/AppContext";
import {
  Button,
  Stack
} from "@mui/material";



type SignOutProps = {
}

const SignOut = ({ }: SignOutProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();



  return (
    <Stack direction="row" spacing={2} alignItems={"center"}>
      <Tracked onClick={{ name: TrackedEventName.USER_SIGNED_OUT }}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<SignOutIcon />}
          onClick={() => logOut?.()}
        >
          Sign Out
        </Button>
      </Tracked>
    </Stack>
  );
};

export default SignOut;