

import { ChargedPer } from "@/models";
import {
  CheckIcon
} from "@components/icons";
import CalendarConnectDialog from "@components/layout/page/CalendarConnectDialog";
import { useAppContext } from "@context/AppContext";
import { useUIMessageContext } from "@context/UIMessageContext";
import {
  Button,
  Divider, Stack, Typography
} from "@mui/material";
import { useDisconnectCalendar } from "@services/meetings/disconnectCalendar";
import { useEffect, useState } from "react";
import MeetingAvailability from "./MeetingAvailability";

type CalendarProps = {
}

const Calendar = ({ }: CalendarProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();
  const { showErrorMessage } = useUIMessageContext();


  const [showCalendarConnectDialog, setShowCalendarConnectDialog] = useState(false);
  const [calendarProfileId, setCalendarProfileId] = useState<string | null>(null)
  const [disconnectCalendar, { loading: isDisconnectingCalendar, data: disconnectCalendarResponse }] = useDisconnectCalendar();

  const handleDisconnectCalendar = async () => {
    if (!lawyerId) return;
    if (!calendarProfileId) return;
    await disconnectCalendar({
      lawyerId,
      profileId: calendarProfileId,
    });
    if (disconnectCalendarResponse?.disconnected) {
      setShowCalendarConnectDialog(false);
    }
    else {
      showErrorMessage('Failed to disconnect calendar');
    }
  };


  useEffect(() => {
    setCalendarProfileId(calendarProfiles?.[0]?.profileId || null);
  }, [isCalendarConnected, hasCalendarProfiles, calendarProfiles]);

  useEffect(() => {
    // console.log('Calendar Profile ID:', calendarProfileId);
  }, [calendarProfileId]);


  return (
    <>
      <CalendarConnectDialog open={showCalendarConnectDialog} />
      {profile?.lawyer?.chargedPer === ChargedPer.MEETING ? (
        <>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2}
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <Stack direction={"column"} spacing={1} width={300}>
              <Typography color="text.secondary" variant="subtitle1">
                Calendar
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>
                You can manage your calendar and connect your calendar
                to Kular.
              </Typography>
            </Stack>
            <div>
              {isCalendarConnected ? (
                <>
                  <Button
                    variant="outlined"
                    sx={{ cursor: 'default' }}
                    color="primary"
                    startIcon={<CheckIcon />}
                  >
                    Connected
                  </Button>
                </>
              ) : hasCalendarProfiles ? (
                <Stack direction="column" spacing={1}>
                  <Typography variant="caption" color="error">
                    Your calendar profile is set up but not connected.
                    Please connect your calendar to enable meeting
                    bookings.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={async () => await handleDisconnectCalendar()}
                  >
                    Connect Calendar
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowCalendarConnectDialog(true)}
                >
                  Connect Calendar
                </Button>
              )}
            </div>
          </Stack>
          <Divider />
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            spacing={2}
          >
            <Stack direction={"column"} spacing={1} width={300}>
              <Typography color="text.secondary" variant="subtitle1">
                Meeting Availability
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>
                You can manage your meeting availability
              </Typography>
            </Stack>

            <MeetingAvailability />
          </Stack>

          <Divider />
        </>
      ) : null}</>
  );
};

export default Calendar;