import {
  ROUTE_CAMPAIGN,
  ROUTE_CONVERSATIONS,
  getRoute,
} from "@/AuthenticatedAppRoutes";
import {
  CampaignChannel,
  ChargedPer,
  LinkedInConnection,
  OutreachMessage,
  emailAddressToString,
  getRecipientsFromHedwigMessagePayloads,
  getSenderNameFromMessage,
} from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import Blurred from "@components/Blurred";
import ContactAvatar from "@components/ContactAvatar";
import ContactTitleText from "@components/ContactTitleText";
import ProfileAvatar from "@components/ProfileAvatar";
import RoundedBox from "@components/RoundedBox";
import Tracked from "@components/Tracked";
import TemplateEditor from "@components/editor/TemplateEditor";
import {
  CampaignIcon,
  ConversationIcon,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  OverviewIcon,
  TwitterIcon,
  WebsiteIcon,
} from "@components/icons";
import SectionCard from "@components/layout/SectionCard";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { CampaignMemberMeetingProvider } from "@context/CampaigmMemberMeetingContext";
import {
  ConversationProvider,
  useConversationContext,
} from "@context/ConversationContext";
import { useConversationsContext } from "@context/ConversationsContext";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import ReplyEditor from "@pages/conversations/conversation/reply/ReplyEditor";
import { usePatchEmailMessage } from "@services/email/patchEmailMessage";
import { ReplyEmailResponse } from "@services/email/replyToEmailMessage";
import { usePatchLinkedinMessage } from "@services/linkedin/patchLinkedinMessage";
import { usePatchCampaignMember } from "@services/members/patchCampaignMember";
import { getMessageDisplayDate } from "@utils/date";
import { getRandomUUID } from "@utils/object";
import { UUID, scrambleWords } from "@utils/text";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ConversationCCDialog from "./ConversationCCDialog";
import ConversationFeedbackBox from "./ConversationFeedbackBox";
import ConversationMeeting from "./ConversationMeeting";
import FreeLeadChip from "./FreeLeadChip";
import InvoiceLink from "./InvoiceLink";
import RefundConversation from "./RefundConversation";
import { ConversationIncreaseBudget } from "./ConversationIncreaseBudget";

enum ConversationTab {
  CONVERSATION = "CONVERSATION",
  OVERVIEW = "OVERVIEW",
}

const ConversationPage = () => {
  const { id: campaignMemberId } = useParams();

  return (
    <Page>
      <PageContent>
        {!!campaignMemberId && (
          <ConversationProvider campaignMemberId={campaignMemberId as UUID}>
            <ConversationContent />
          </ConversationProvider>
        )}
      </PageContent>
    </Page>
  );
};

const ConversationContent = () => {
  // Profile
  const { profile, isInternalProfile } = useAppContext();
  // Get Conversation
  const {
    conversation,
    contact,
    fetchConversation,
    lastReply,
    messages,
    isBlurred,
    isVisible,
    isFree,
    visibility,
    contactFullName,
    campaignMemberMeetingId,
    charge,
    campaignId,
  } = useConversationContext();

  // Update unread count
  const { fetchUnreadConversations } = useConversationsContext();

  // Load conversation

  // Profile full name
  // Tab
  const [tabValue, setTabValue] = useState<ConversationTab>(
    ConversationTab.CONVERSATION
  );

  // Handle conversation tabs change
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as ConversationTab);
  };

  // Connected on LinkedIn
  const isConnectedOnLinkedin = useMemo(() => {
    const { linkedinMessages } = conversation || {};

    return linkedinMessages?.some(({ campaign }) =>
      campaign?.members?.some((member) =>
        member?.connections?.some(
          (connection: LinkedInConnection) => connection.status === "ACCEPTED"
        )
      )
    );
  }, [conversation]);

  // Set lawyer viewed time for all messages
  const [patchCampaignMember] = usePatchCampaignMember();

  const [patchEmailMessage] = usePatchEmailMessage();
  const [patchLinkedinMessage] = usePatchLinkedinMessage();

  const markMessageAsRead = async (message: OutreachMessage) => {
    if (!message.id) return;
    if (message.lawyerViewedTime) return;
    if (!campaignId) return;
    if (!conversation?.id) return;
    //if (isInternalProfile) return;

    if (message.campaignChannel === CampaignChannel.EMAIL) {
      await patchEmailMessage(
        {
          emailMessageId: message.id,
          lawyerViewedTime: new Date(),
        },
        {
          silent: true,
        }
      );
    }

    if (message.campaignChannel === CampaignChannel.LINKEDIN) {
      await patchLinkedinMessage(
        {
          linkedinMessageId: message.id,
          lawyerViewedTime: new Date(),
        },
        {
          silent: true,
        }
      );
    }

    if (conversation?.lawyerViewedTime) return;

    await patchCampaignMember(
      {
        campaignId: campaignId,
        memberId: conversation.id,
        lawyerViewedTime: new Date(),
      },
      {
        silent: true,
      }
    );

    fetchConversation?.();
    fetchUnreadConversations?.();
  };

  const markConversationAsRead = async () => {
    if (isInternalProfile) return;
    if (!messages?.length) return;

    await Promise.all(messages?.map((message) => markMessageAsRead(message)));
  };

  useEffect(() => {
    if (isInternalProfile) return;

    markConversationAsRead();
  }, [isInternalProfile, messages]);

  // Last message
  const lastMessage = useMemo(() => {
    return messages?.[messages?.length - 1];
  }, [messages]);

  // Show CC dialog
  const [showCCDialog, setShowCCDialog] = useState(false);

  const handleCloseCCDialog = () => {
    setShowCCDialog(false);
  };

  // Campaign Channel
  const campaignChannel = useMemo(() => {
    return lastMessage?.campaignChannel;
  }, [lastMessage]);

  // Should show reply
  const shouldShowReply = useMemo(() => {
    return (
      campaignChannel === CampaignChannel.EMAIL
    );
  }, [profile, campaignChannel]);

  // Should show conversation meeting
  const shouldShowConversationMeeting = useMemo(() => {
    return (
      profile?.lawyer?.chargedPer === ChargedPer.MEETING &&
      !!campaignMemberMeetingId
    );
  }, [profile, campaignMemberMeetingId]);

  // If there is a query param named "meeting"
  const [searchParams] = useSearchParams();

  const scrollToMeeting = () => {
    const conversationMeeting = document.getElementById("conversation-meeting");
    if (conversationMeeting) {
      conversationMeeting.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (searchParams.get("meeting")) {
      scrollToMeeting();
    }
  }, [searchParams]);

  // Conversation messages
  const [conversationMessages, setConversationMessages] = useState<
    OutreachMessage[]
  >(messages || []);

  useEffect(() => {
    setConversationMessages(messages || []);
  }, [messages]);

  const handleReply = (replyResponse: ReplyEmailResponse) => {
    //replyToEmailMessage(replyCreate);
    const fakeReplyMessage: OutreachMessage = {
      id: getRandomUUID(),
      content: replyResponse.contentText,
      isLawyer: true,
      sentTime: new Date(),
    };

    setConversationMessages([...conversationMessages, fakeReplyMessage]);
  };

  return isVisible ? (
    <>
      {isBlurred && <ConversationIncreaseBudget center={true} />}
      <Blurred isBlurred={isBlurred}>
        <SectionCard
          skinny
          withBackButton
          backButtonUrl={ROUTE_CONVERSATIONS}
          backButtonTitle="Back to Conversations"
          title={
            <Stack direction={"column"} spacing={0} alignItems={"flex-start"}>
              {isFree ? <FreeLeadChip visibility={visibility} /> : null}
              <Typography variant="h5" component={"h1"}>
                {contactFullName}
              </Typography>
            </Stack>
          }
          titleIcon={<ContactAvatar contact={contact} size={65} />}
          subTitle={
            <Stack direction="column" spacing={0}>
              <ContactTitleText contact={conversation} />
              {!!lastReply?.campaign?.name && (
                <Box
                  sx={{
                    a: {
                      color: "text.secondary",
                      textDecoration: "none",
                      ":hover": {
                        color: "primary.main",
                      },
                    },
                  }}
                >
                  <Link
                    to={getRoute(ROUTE_CAMPAIGN, {
                      id: lastReply?.campaign?.id,
                    })}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CampaignIcon fontSize="medium" />
                      <Typography variant="body2" whiteSpace={"nowrap"}>
                        {lastReply?.campaign?.name}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              )}
            </Stack>
          }
          titleAction={
            <Stack direction="row" spacing={1} alignItems={"center"} mr={2}>
              <InvoiceLink invoiceUrl={charge?.stripeInvoiceUrl} />
              <RefundConversation />
            </Stack>
          }
        >
          {!!conversation && (
            <ConversationFeedbackBox mb={2} campaignMember={conversation} />
          )}
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tracked
                onChange={{ name: TrackedEventName.CONVERSATION_TAB_CHANGED }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="conversation tabs"
                >
                  <Tab
                    label="Conversation"
                    value={ConversationTab.CONVERSATION}
                    icon={<ConversationIcon />}
                  />
                  <Tab
                    label="Overview"
                    value={ConversationTab.OVERVIEW}
                    icon={<OverviewIcon />}
                  />
                </TabList>
              </Tracked>
            </Box>
            <TabPanel value={ConversationTab.CONVERSATION}>
              <Timeline
                sx={{
                  m: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {conversationMessages?.map((message, index) => {
                  const recipients = getRecipientsFromHedwigMessagePayloads(message.hedwigMessagePayloads);
                  return (
                    <TimelineItem key={message?.id} id={message?.id}>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <RoundedBox
                          borderRadius={2}
                          p={4}
                          bgcolor={
                            message?.isLawyer
                              ? "background.paper"
                              : "background.info"
                          }
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            mb={3}
                          >
                            {message?.isLawyer ? (
                              <>
                                <ProfileAvatar size={55} />
                              </>
                            ) : (
                              <>
                                <ContactAvatar contact={contact} size={55} />
                              </>
                            )}

                            <Stack direction="column" spacing={0}>
                              <Typography variant="subtitle1">
                                {message?.senderProfile?.isShared && message?.isLawyer ? "Kular" : getSenderNameFromMessage(
                                  message,
                                  contactFullName || null
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                color={"text.secondary"}
                              >
                                {getMessageDisplayDate(message)}
                              </Typography>
                              {message?.subject && (
                                <Typography
                                  variant="caption"
                                  color={"text.secondary"}
                                >
                                  Subject: {message?.subject}
                                </Typography>
                              )}
                              {recipients?.to.length > 1 && (
                                <Typography
                                  variant="caption"
                                  color={"text.secondary"}
                                >
                                  To: {recipients?.to.map((recipient) => emailAddressToString(recipient)).join(", ")}
                                </Typography>
                              )}
                              {recipients?.cc.length > 0 && (
                                <Typography
                                  variant="caption"
                                  color={"text.secondary"}
                                >
                                  CC: {recipients?.cc.map((recipient) => emailAddressToString(recipient)).join(", ")}
                                </Typography>
                              )}
                              {recipients?.bcc.length > 0 && (
                                <Typography
                                  variant="caption"
                                  color={"text.secondary"}
                                >
                                  BCC: {recipients?.bcc.map((recipient) => emailAddressToString(recipient)).join(", ")}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Typography
                            variant="body2"
                            textTransform={"none"}
                            whiteSpace={"pre-line"}
                          >
                            <TemplateEditor
                              readonly
                              value={
                                (!isBlurred
                                  ? message?.content
                                  : scrambleWords(message?.content)) || ""
                              }
                            />
                          </Typography>
                          <Box mt={2} />
                        </RoundedBox>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
                {shouldShowReply && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <ReplyEditor onReply={handleReply} />
                    </TimelineContent>
                  </TimelineItem>
                )}

                {shouldShowConversationMeeting && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <CampaignMemberMeetingProvider
                        campaignMemberMeetingId={campaignMemberMeetingId!}
                      >
                        <Box mb={2}>
                          <ConversationMeeting />
                        </Box>
                      </CampaignMemberMeetingProvider>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </Timeline>

              {lastMessage && (
                <ConversationCCDialog
                  open={showCCDialog}
                  onClose={handleCloseCCDialog}
                  message={lastMessage}
                />
              )}
            </TabPanel>
            <TabPanel value={ConversationTab.OVERVIEW}>
              {!!contact?.company && (
                <RoundedBox
                  p={3}
                  bgcolor={"background.info"}
                  whiteSpace={"break-spaces"}
                >
                  <Stack direction="row" spacing={4} alignItems={"flex-start"}>
                    <Avatar
                      sx={{ bgcolor: "primary.main", width: 55, height: 55 }}
                      alt={contact?.company?.name || ""}
                      src={contact?.company?.logoUrl || ""}
                    />

                    <Box>
                      <Typography variant="subtitle1" component={"p"}>
                        {contact?.company?.name}
                      </Typography>
                      <Stack direction="row" spacing={0} alignItems={"center"}>
                        {!!contact?.company?.websiteUrl && (
                          <Link
                            to={contact?.company.websiteUrl}
                            target="_blank"
                          >
                            <IconButton>
                              <WebsiteIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        )}
                        {!!contact?.company?.linkedinUrl && (
                          <Link
                            to={contact?.company?.linkedinUrl}
                            target="_blank"
                          >
                            <IconButton>
                              <LinkedinIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        )}
                        {!!contact?.company?.facebookUrl && (
                          <Link
                            to={contact?.company.facebookUrl}
                            target="_blank"
                          >
                            <IconButton>
                              <FacebookIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        )}
                        {!!contact?.company?.twitterUrl && (
                          <Link
                            to={contact?.company?.twitterUrl}
                            target="_blank"
                          >
                            <IconButton>
                              <TwitterIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        )}
                      </Stack>
                      <Box mb={1} />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component={"p"}
                      >
                        {contact?.company?.summary}
                      </Typography>
                    </Box>
                  </Stack>
                </RoundedBox>
              )}
              <Box mb={2} />

              <RoundedBox p={3} bgcolor={"background.info"}>
                <Stack direction="row" spacing={4} alignItems={"flex-start"}>
                  <ContactAvatar contact={conversation} size={55} />
                  <Box>
                    <Typography variant="subtitle1">
                      {contactFullName}
                    </Typography>
                    <Stack direction="row" spacing={0} alignItems={"center"}>
                      {!!contact?.email && (
                        <Link to={`mailto:${contact?.email}`} type="email">
                          <IconButton>
                            <EmailIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      )}
                      {!!contact?.linkedinUrl && (
                        <Link to={contact?.linkedinUrl} target="_blank">
                          <IconButton>
                            <LinkedinIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      )}
                      {!!contact?.twitterUrl && (
                        <Link to={contact?.twitterUrl} target="_blank">
                          <IconButton>
                            <TwitterIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      )}
                      {!!contact?.facebookUrl && (
                        <Link to={contact?.facebookUrl} target="_blank">
                          <IconButton>
                            <FacebookIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      )}
                    </Stack>
                    <Box mb={1} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component={"p"}
                    >
                      {contact?.summary}
                    </Typography>
                  </Box>
                </Stack>
                <br />

                {isConnectedOnLinkedin && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems={"center"}
                    mt={3}
                    justifyContent={"space-between"}
                  >
                    <Chip
                      label=" Connected on LinkedIn via Kular"
                      icon={<CheckCircleOutlineOutlinedIcon />}
                      variant="filled"
                      color="info"
                    />
                  </Stack>
                )}
              </RoundedBox>
            </TabPanel>
          </TabContext>
          <Box mb={1} />
        </SectionCard>
      </Blurred>
    </>
  ) : null;
};

export default ConversationPage;
