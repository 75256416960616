
import {
  EmailIcon
} from "@components/icons";
import {
  Button,
  Divider,
  Stack,
  Typography
} from "@mui/material";

import { useGetBringYourOwnEmailStatus } from "@services/hedwig/bringYourOwnEmailStatus";
import { useEffect, useState } from "react";
import AuthenticateEmail from "./AuthenticateEmail";
import BYOBDialog from "./Dialog";

const BringYourOwnEmail = () => {
  const [getBringYourOwnEmailStatus, { data: bringYourOwnEmailStatus }] = useGetBringYourOwnEmailStatus();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onClose = () => {
    setDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  }

  useEffect(() => {
    getBringYourOwnEmailStatus({});
  }, []);

  return (
    <>
      <BYOBDialog open={dialogOpen} onClose={onClose} email={""} />
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <EmailIcon color="primary" />
            <Typography color="text.secondary" variant="subtitle1">
              Kular Inbox Integration
            </Typography>
          </Stack>
          <Typography variant="caption" color={"text.secondary"}>
            Integrate your inbox to send emails with Kular
          </Typography>
        </Stack>

        <Stack direction={"column"} spacing={2} maxWidth={600}>
          {bringYourOwnEmailStatus && bringYourOwnEmailStatus.length > 0 && bringYourOwnEmailStatus.map((b) => (
            <AuthenticateEmail email={b.email} hedwigStatus={b.hedwigStatus} key={b.email} />
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenDialog}
          >
            Integrate
          </Button>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

export default BringYourOwnEmail;
