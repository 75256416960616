import { Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type LawyerDetailsParams = {
  lawyerId: UUID;
};

export const getLawyerDetails = async ({ lawyerId }: LawyerDetailsParams) => {
  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/web/lawyers/${lawyerId}/`,
    options
  );

  return response;
};

export const useGetLawyerDetails = () =>
  useServiceCall<Lawyer, LawyerDetailsParams>({
    serviceCall: getLawyerDetails,
  });
