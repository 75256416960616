import CancelIcon from '@mui/icons-material/Cancel';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";

type AuthenticateEmailProps = {
  email: string;
  hedwigStatus: boolean;
}

const AuthenticateEmail = ({
  email,
  hedwigStatus
}: AuthenticateEmailProps) => {
  const [bringYourOwnEmail, setBringYourOwnEmail] = useState<string>("");


  useEffect(() => {
    setBringYourOwnEmail(email)
  }, [email])

  return (
    <>

      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={"flex-start"}
        spacing={2}
      >
        <Box width={300}>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems={"flex-start"}
            spacing={2}
          >
            {hedwigStatus ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
            <Typography
              variant="body1"
            >
              {bringYourOwnEmail}
            </Typography>

          </Stack>
        </Box>

      </Stack>

    </>
  );
};

export default AuthenticateEmail;
