import ReferralsTable from "@components/ReferralsTable";
import {
  ArrowRightIcon,
  CopyIcon
} from "@components/icons";
import { useAppContext } from "@context/AppContext";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  Zoom
} from "@mui/material";
import { useState } from "react";



type ReferralsProps = {
}

const Referrals = ({ }: ReferralsProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();
  const [copyTooltipText, setCopyTooltipText] = useState('Copy link');
  // Referral link
  const referralLink = lawyerPublicId
    ? `https://www.bookmeetings.pro/referrals/${lawyerPublicId}`
    : null;
  const copyReferralLink = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
      setCopyTooltipText('Link copied to clipboard');
      setTimeout(() => setCopyTooltipText('Copy link'), 1500); // Reset after 1.5 seconds
    }
  };

  const openReferralLink = () => {
    if (referralLink) {
      window.open(referralLink, '_blank');
    }
  };


  return (

    <>


      {!!referralLink && (
        <>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems="center"
            spacing={2}
          >
            <Stack direction={"column"} spacing={1} width={300}>
              <Typography color="text.secondary" variant="subtitle1">
                Referrals
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>
                Receive one free lead for each customer you refer to
                Kular. The credit will be issued as soon as they sign up
                for a Kular free trial.
              </Typography>
            </Stack>

            <Box width={400}>
              <OutlinedInput
                fullWidth
                readOnly
                value={referralLink}
                size="small"
                onClick={(e) => {
                  (e.target as HTMLInputElement).select();
                  copyReferralLink();
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={copyTooltipText}
                      arrow
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          copyReferralLink();
                        }}
                        edge="end"
                        size="small"
                      >
                        <CopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Visit link" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          openReferralLink();
                        }}
                        edge="end"
                        size="small"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </Box>
          </Stack>
          <ReferralsTable lawyerId={lawyerId} />
          <Divider />
        </>
      )}
    </>
  );
};

export default Referrals;