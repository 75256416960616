import { Box, Button, Link, Typography } from "@mui/material";

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { BYOBStage } from "./types";





interface InstructionsProps {
  setStage: (stage: BYOBStage) => void;
}

const GodaddyInstructions = (
  { setStage }: InstructionsProps
) => {

  const handleReady = () => {
    setStage(BYOBStage.ACTUALLY_AUTHENTICATE_GODADDY);
  }

  return (
    <>
      <Typography variant="h6">
        2. Connect your Godaddy Microsfot Office 365 account to Kular
      </Typography>


      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingLeft: 2, marginTop: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            1.
          </Typography>
          <Typography variant="body1">
            On your computer, log in to your <Link href="https://productivity.godaddy.com/#/" target="_blank" rel="noopener"><Typography component="span" fontWeight="bold">Godaddy Account</Typography></Link>.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            2.
          </Typography>
          <Typography variant="body1">
            Click the <Typography component="span" fontWeight="bold">Manage</Typography> button for the email you want to add to Kular.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            3.
          </Typography>
          <Typography variant="body1">
            Scroll to the bottom of the page and click the <Typography component="span" fontWeight="bold">Advanced Settings</Typography> button on the bottom right.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            4.
          </Typography>
          <Typography variant="body1">
            Turn on the switch for SMTP Authentication.
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReady}
        endIcon={<AlternateEmailIcon />}
        sx={{
          marginTop: 2,
        }}
      >
        I'm Ready
      </Button>
    </>
  )
}

export default GodaddyInstructions;