import { Button, Stack, TextField, Typography } from "@mui/material";
import { useGetOAuthUrl } from "@services/hedwig/getOAuthURL";
import { useEffect, useState } from "react";



interface ActuallyAuthenticateEmailProps {
  email: string;
}

const ActuallyAuthenticateEmail = (
  { email }: ActuallyAuthenticateEmailProps
) => {
  const [getOAuthUrl, { data: oauthUrl }] = useGetOAuthUrl();
  const [bringYourOwnEmail, setBringYourOwnEmail] = useState<string>("");

  const authenticateOwnEmail = async () => {
    await getOAuthUrl({ email: bringYourOwnEmail });
  }


  useEffect(() => {
    setBringYourOwnEmail(email)
  }, [email])

  useEffect(() => {
    if (oauthUrl?.oauthUrl) {
      console.log('Going to oauthUrl:', oauthUrl);
      window.open(oauthUrl.oauthUrl, '_blank');
    }
  }, [oauthUrl])

  return (
    <>
      <Typography variant="h6">
        Authenticate Your Email
      </Typography>

      <Stack direction={"row"} spacing={1} alignItems={"center"} paddingTop={2}>
        <TextField
          size="small"
          variant="outlined"
          value={bringYourOwnEmail || ""}
          onChange={(e) => setBringYourOwnEmail(e.target.value)}
          label="Enter your Email"
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={authenticateOwnEmail}
          disabled={bringYourOwnEmail === ""}
        >
          Authenticate
        </Button>
      </Stack>
    </>
  )
}

export default ActuallyAuthenticateEmail;