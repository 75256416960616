import { AutoPilotIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import {
  Divider,
  Stack,
  Typography
} from "@mui/material";
import AutoPilotControl from "./AutoPilotControl";



type AutoPilotModeProps = {
}

const AutoPilotMode = ({ }: AutoPilotModeProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();



  return (
    <>

      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <AutoPilotIcon color="primary" />
            <Typography color="text.secondary" variant="subtitle1">
              Auto Pilot Mode
            </Typography>
          </Stack>
          <Typography variant="caption" color={"text.secondary"}>
            You can let Kular to manage your campaigns automatically.
          </Typography>
        </Stack>

        <AutoPilotControl />
      </Stack>
      <Divider />
    </>
  );
};

export default AutoPilotMode;
