// with mui add a icon button when clicked shows a list of options
// It will only have the uption to duplicate the campaign

import { CampaignChannel } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import AreYouSureDialog from "@components/AreYouSureDialog";
import Tracked from "@components/Tracked";
import { CopyIcon, DeleteIcon, HorizontalMore } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useCampaignContext } from "@context/CampaignContext";
import { useCampaignsContext } from "@context/CampaignsContext";
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const CampaignSettings: React.FC = () => {
  const { profile } = useAppContext();
  const { fetchCampaigns } = useCampaignsContext();
  const {
    campaign,
    isDuplicatingCampaign,
    isPatchingCampaign,
    duplicateCampaign,
    patchCampaign,
  } = useCampaignContext();

  const [isDropdownOpen, setDropdownOpen] = useState<null | HTMLElement>(null);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropdownOpen(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(null);
  };

  const handleDuplicateCampaign = async () => {
    if (!profile) return;
    if (!campaign) return;

    await duplicateCampaign(campaign.id, campaign.channel);
    fetchCampaigns?.();
    handleDropdownClose();
  };

  const handleDuplicateToLinkedIn = async () => {
    if (!profile) return;
    if (!campaign) return;

    await duplicateCampaign(campaign.id, CampaignChannel.LINKEDIN);
    fetchCampaigns?.();
    handleDropdownClose();
  };

  const handleDeleteCampaign = async () => {
    if (!profile) return;
    if (!campaign) return;
    await patchCampaign(
      {
        campaignId: campaign.id,
        deleted: true,
      },
      {
        successMessage: "Campaign deleted successfully.",
        errorMessage: "Failed to delete campaign",
      }
    );

    handleDropdownClose();
  };

  // Show delete campaign dialog
  const [showDeleteCampaignDialog, setShowDeleteCampaignDialog] =
    useState(false);

  return (
    <>
      <Tracked
        onClick={{
          name: TrackedEventName.CAMPAIGN_MENU_EXPANDED,
        }}
      >
        <IconButton onClick={handleDropdownOpen} color="inherit">
          <HorizontalMore color="primary" />
        </IconButton>
      </Tracked>
      <Popover
        id="campaign-settings-popover"
        anchorEl={isDropdownOpen}
        open={Boolean(isDropdownOpen)}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiPaper-root": {
            width: "250px",
          },
        }}
      >
        <List>
          <ListItem
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleDuplicateCampaign();
            }}
          >
            <ListItemText>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CopyIcon fontSize="small" color="primary" />
                <Typography variant="subtitle2" color="primary">
                  Duplicate Campaign
                </Typography>
                {isDuplicatingCampaign && (
                  <CircularProgress size={16} color="primary" />
                )}
              </Stack>
            </ListItemText>
          </ListItem>

          {campaign?.channel !== CampaignChannel.LINKEDIN && (
            <ListItem
              sx={{ cursor: "pointer" }}
              onClick={() => handleDuplicateToLinkedIn()}
            >
              <ListItemText>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CopyIcon fontSize="small" color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    Duplicate to LinkedIn
                  </Typography>
                  {isDuplicatingCampaign && (
                    <CircularProgress size={16} color="primary" />
                  )}
                </Stack>
              </ListItemText>
            </ListItem>
          )}

          <ListItem
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDeleteCampaignDialog(true);
            }}
          >
            <ListItemText>
              <Stack direction="row" alignItems="center" spacing={1}>
                <DeleteIcon fontSize="small" color="error" />
                <Typography variant="subtitle2" color="error">
                  Delete Campaign
                </Typography>
                <AreYouSureDialog
                  title="Delete Campaign"
                  text="Are you sure you want to delete this campaign permanently?"
                  open={showDeleteCampaignDialog}
                  onClose={() => {
                    setShowDeleteCampaignDialog(false);
                  }}
                  onConfirm={handleDeleteCampaign}
                />

                {isPatchingCampaign && (
                  <CircularProgress size={16} color="primary" />
                )}
              </Stack>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default CampaignSettings;
