import { TrackedEventName } from "@/third-party/tracking";
import ProfileAvatar from "@components/ProfileAvatar";
import SectionCard from "@components/layout/SectionCard";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { LoadingButton } from "@mui/lab";
import {
  Stack
} from "@mui/material";
import BringYourOwnEmails from "@pages/profile/BringYourOwnEmails";
import { useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { isK4KLawyer } from "@utils/organisations";
import { getFullName } from "@utils/text";
import { useEffect, useState } from "react";
import AuthenticateLinkedin from "./AuthenticateLinkedin";
import AutoPilotMode from "./AutoPilotMode";
import Calendar from "./Calendar";
import CompanyName from "./CompanyName";
import Email from "./Email";
import GoToPayments from "./GoToPayments";
import LinkedIn from "./LinkedIn";
import PauseAccount from "./PauseAccount";
import Referrals from "./Referrals";
import SignOut from "./SignOut";
import UnsubscribedLeads from "./UnsubscribedLeads";
import Website from "./Website";


const ProfilePage = () => {
  const { profile, lawyerId, fetchProfile } = useAppContext();
  const [patchLawyer, { loading: isPatchingLawyer }] = useUpdateLawyer();

  const [websiteUrl, setWebsiteUrl] = useState<string | null>("");
  const [linkedinUrl, setLinkedinUrl] = useState<string | null>("");


  const handleSaveProfile = async () => {
    if (!lawyerId) return;
    if (!showSaveButton) return;

    await patchLawyer(
      {
        lawyerId: lawyerId,
        websiteUrl,
        linkedinUrl,
      },
      {
        successMessage: "Profile updated successfully",
        track: {
          name: TrackedEventName.PROFILE_UPDATED,
        },
      }
    );

    fetchProfile?.();
  };

  const showSaveButton = (
    (websiteUrl && websiteUrl !== profile?.lawyer?.websiteUrl) ||
    (linkedinUrl && linkedinUrl !== profile?.lawyer?.linkedinUrl)
  )

  useEffect(() => {
    setWebsiteUrl(profile?.lawyer?.websiteUrl || null);
  }, [profile?.lawyer?.websiteUrl]);

  useEffect(() => {
    setLinkedinUrl(profile?.lawyer?.linkedinUrl || null);
  }, [profile?.lawyer?.linkedinUrl]);

  return (
    <>
      <Page>
        <PageContent>

          <SectionCard
            withBackButton
            skinny
            title={getFullName(profile?.firstName, profile?.lastName) || ""}
            titleIcon={<ProfileAvatar size={55} />}
            titleAction={
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!showSaveButton}
                loading={isPatchingLawyer}
                onClick={handleSaveProfile}
              >
                Save Profile
              </LoadingButton>
            }
          >
            <br />
            <Stack direction="column" spacing={2}>
              <CompanyName />
              <Email />
              <Website websiteUrl={websiteUrl} setWebsiteUrl={setWebsiteUrl} />
              <LinkedIn linkedinUrl={linkedinUrl} setLinkedinUrl={setLinkedinUrl} />
              <AutoPilotMode />
              <AuthenticateLinkedin />
              {isK4KLawyer(profile) && <BringYourOwnEmails />}
              <GoToPayments />
              <Calendar />
              <Referrals />
              <PauseAccount />
              <UnsubscribedLeads />
              <SignOut />
            </Stack>
          </SectionCard>
        </PageContent>
      </Page>
    </>
  );
};

export default ProfilePage;
