

import { DownloadIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { LoadingButton } from "@mui/lab";
import { Divider, Stack, Typography } from "@mui/material";
import { useGetUnsubscribeList } from "@services/campaigns/getUnsubscribeList";
import { useEffect } from "react";

type UnsubscribedLeadsProps = {
}

const UnsubscribedLeads = ({ }: UnsubscribedLeadsProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();

  const [
    getUnsubscribeList,
    { data: unsubscribeListResponse, loading: isLoadingUnsubscribeList },
  ] = useGetUnsubscribeList();


  const downloadList = () => {
    if (unsubscribeListResponse) {
      const downloadUrl = window.URL.createObjectURL(unsubscribeListResponse);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Kula_Unsubscribed_List.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  useEffect(() => {
    if (unsubscribeListResponse) {
      downloadList();
    }
  }, [unsubscribeListResponse]);


  const handleGetUnsubscribedList = async () => {
    if (!lawyerId) return;

    await getUnsubscribeList({
      lawyerId: lawyerId,
    });
  };

  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Typography color="text.secondary" variant="subtitle1">
            Unsubscribed Leads
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            You can download all of the leads that wanted to unsubscribe
            from your campaigns.
          </Typography>
        </Stack>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoadingUnsubscribeList}
          startIcon={<DownloadIcon />}
          onClick={(e) => handleGetUnsubscribedList()}
        >
          Download Unsubscribed
        </LoadingButton>
      </Stack>
      <Divider />
    </>

  );
};

export default UnsubscribedLeads;