

import { WarningIcon } from "@components/icons";
import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

type WebsiteProps = {
  websiteUrl: string | null
  setWebsiteUrl: React.Dispatch<React.SetStateAction<string | null>>
}

const Website = ({
  websiteUrl, setWebsiteUrl
}: WebsiteProps) => {
  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={"flex-start"}
        spacing={2}
      >
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          width={300}
        >
          {!websiteUrl && (
            <WarningIcon color="error" fontSize="small" />
          )}
          <Typography
            color={!websiteUrl ? "error" : "text.secondary"}
            variant="subtitle1"
          >
            Website
          </Typography>
        </Stack>
        <Box width={400}>
          <TextField
            size="small"
            variant="outlined"
            value={websiteUrl || ""}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            label="Your Website URL"
            fullWidth
          />
        </Box>
      </Stack>
      <Divider />
    </>
  );
};

export default Website;
