

import { useAppContext } from "@context/AppContext";
import {
  Divider,
  Stack,
  Typography
} from "@mui/material";

type CompanyNameProps = {
}

const CompanyName = ({ }: CompanyNameProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();


  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={"flex-start"}
        spacing={2}
      >
        <Typography
          width={300}
          color="text.secondary"
          variant="subtitle1"
        >
          Company Name
        </Typography>
        <Typography variant="body1">
          {profile?.lawyer?.firmName}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
};

export default CompanyName;
