import GoogleIcon from '@mui/icons-material/Google';
import { Button, Stack, Typography } from "@mui/material";

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { BYOBStage } from './types';


interface ChooseEmailProviderProps {
  setStage: (stage: BYOBStage) => void;
}

const ChooseEmailProvider = (
  { setStage }: ChooseEmailProviderProps
) => {

  const handleGoogleClick = () => {
    setStage(BYOBStage.READ_GOOGLE_INSTRUCTIONS_IMAP);
  }

  const handleMicrosoftClick = () => {
    setStage(BYOBStage.READ_OUTLOOK_INSTRUCTIONS);
  }

  const handleGodaddyClick = () => {
    setStage(BYOBStage.READ_GODADDY_INSTRUCTIONS);
  }

  return (
    <>
      <Typography variant="h6">
        1. Choose Your Email Provider
      </Typography>

      <Stack
        direction={"row"}
        spacing={4}
        alignItems={"center"}
        justifyItems={"center"}
        margin={4}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoogleClick}
          startIcon={<GoogleIcon />}

        >
          G-suite (Google)
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleMicrosoftClick}
          startIcon={<MicrosoftIcon />}

        >
          Office 365 (Outlook)
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGodaddyClick}
          startIcon={<AlternateEmailIcon />}
        >
          Office 365 (Godaddy)
        </Button>
      </Stack>
    </>
  )
}

export default ChooseEmailProvider;