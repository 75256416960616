import { ROUTE_CAMPAIGNS } from "@/AuthenticatedAppRoutes";
import { CampaignChannel, Prospect } from "@/models";
import { useDebounce } from "@hooks/useDebounce";
import { useCreateCampaign } from "@services/campaigns/createCampaign";
import { useCreateCampaignRequest } from "@services/campaigns/createCampaignRequest";
import { useSearchProspects } from "@services/campaigns/searchProspects";
import { prospectDisplayScore } from "@utils/sorting";
import { UUID } from "@utils/text";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import { useCampaignsContext } from "./CampaignsContext";

const SEARCH_PROSPECTS_PAGE_SIZE = 10;

interface CampaignCreateContextProps {
  activeStep: number;
  campaignChannel: CampaignChannel;
  companyTargetRequest: string;
  focuses: string;
  prospects: Array<Prospect> | null;
  canSearchProspects: boolean;
  canCreateCampaign: boolean;
  isCreatingCampaign: boolean;
  isSearchingProspects: boolean;
  isCreatingCampaignRequest: boolean;
  campaignRequestError: Error | null;
  suggestedTargetingId: UUID | null;
  estimatedLeadsText: string | null;
  setEstimatedLeadsText: Dispatch<SetStateAction<string | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setCompanyTargetRequest: Dispatch<SetStateAction<string>>;
  setFocuses: Dispatch<SetStateAction<string>>;
  setCampaignChannel: Dispatch<SetStateAction<CampaignChannel>>;
  handleSearchProspects: () => void;
  handleCreateCampaign: () => void;
}

// Create the context
const CampaignCreateContext = createContext<
  CampaignCreateContextProps | undefined
>(undefined);

// Create a custom hook to use the context
const useCampaignCreateContext = () => {
  const context = useContext(CampaignCreateContext);
  if (!context) {
    throw new Error(
      "useCampaignCreateContext must be used within a CampaignCreateContextProvider"
    );
  }
  return context;
};

interface CampaignCreateContextProviderProps {
  children: React.ReactNode;
}

export enum CampaignCreateSteps {
  TARGETING = 0,
  PROSPECTS = 1,
  FOCUSES = 2,
  CHANNEL = 3,
  CONFIRM = 4,
}
// Create the context provider
const CampaignCreateContextProvider = ({
  children,
}: CampaignCreateContextProviderProps) => {
  // Profile
  const { profile } = useAppContext();

  // fetchCampaigns
  const { fetchCampaigns } = useCampaignsContext();

  // Active Step
  const [activeStep, setActiveStep] = useState<CampaignCreateSteps>(
    CampaignCreateSteps.TARGETING
  );

  // Campaign Creation data
  const [campaignChannel, setCampaignChannel] = useState<CampaignChannel>(
    CampaignChannel.EMAIL
  );
  const [companyTargetRequest, setCompanyTargetRequest] = useState<string>("");
  const debouncedCompanyTargetRequest = useDebounce(companyTargetRequest, 10000);
  const [focuses, setFocuses] = useState<string>("");

  // Create campaign Request
  const [lastSearchCompanyTargetRequest, setLastSearchCompanyTargetRequest] =
    useState<string>();
  const [
    createCampaignRequest,
    {
      data: campaignRequests,
      loading: isCreatingCampaignRequest,
      error: campaignRequestError,
    },
  ] = useCreateCampaignRequest();

  // Search prospects
  const [
    searchProspects,
    { data: prospectsData, loading: isSearchingProspects },
  ] = useSearchProspects();

  const isNewSearch = useMemo(() => {
    return lastSearchCompanyTargetRequest !== companyTargetRequest;
  }, [lastSearchCompanyTargetRequest, companyTargetRequest]);

  const isValidSearch = useMemo(() => {
    return (
      !!debouncedCompanyTargetRequest &&
      debouncedCompanyTargetRequest.length >= 5
    );
  }, [debouncedCompanyTargetRequest]);

  const canSearchProspects = useMemo(() => {
    return isValidSearch && !isSearchingProspects && !isCreatingCampaignRequest;
  }, [companyTargetRequest, isSearchingProspects, isCreatingCampaignRequest]);

  const handleSearchProspects = () => {
    if (!canSearchProspects && !isNewSearch) return;
    if (!profile?.lawyer?.id) return;

    setLastSearchCompanyTargetRequest(companyTargetRequest);

    createCampaignRequest({
      lawyerId: profile?.lawyer?.id,
      description: companyTargetRequest,
      focuses: focuses,
    });
  };

  const suggestedTargetingId = useMemo(() => {
    return campaignRequests?.[0]?.suggestedTargeting?.id ?? null;
  }, [campaignRequests]);

  useEffect(() => {
    if (!profile?.lawyer?.id) return;
    if (!suggestedTargetingId) return;

    searchProspects({
      suggestedTargetingId,
      page: 1,
      size: SEARCH_PROSPECTS_PAGE_SIZE,
    });
  }, [profile?.lawyer?.id, suggestedTargetingId]);

  // Search prospects when there is new companyTargetRequest or focuses
  // useEffect(() => {
  //   handleSearchProspects();
  // }, [debouncedCompanyTargetRequest]);

  const prospects =
    prospectsData?.items?.sort((a, b) => {
      return prospectDisplayScore(b) - prospectDisplayScore(a);
    }) ?? null;

  // Create campaign
  const [
    createCampaign,
    { data: createdCampaign, loading: isCreatingCampaign },
  ] = useCreateCampaign();

  // Can create campaign
  const canCreateCampaign = useMemo(() => {
    return (
      !!campaignRequests?.length &&
      !isCreatingCampaign &&
      !isCreatingCampaignRequest
    );
  }, [
    campaignRequests,
    isCreatingCampaign,
    isCreatingCampaignRequest,
  ]);

  // Create campaign
  const navigate = useNavigate();
  const handleCreateCampaign = async () => {
    if (!canCreateCampaign) return;
    if (!profile?.lawyer?.id) return;
    if (!campaignRequests?.length) return;

    const primaryCampaignRequest = campaignRequests[0];
    try {
      await createCampaign({
        lawyerId: profile?.lawyer?.id,
        campaignChannel,
        suggestedTargetingId: primaryCampaignRequest?.suggestedTargeting?.id,
      });
      // Sleep for 1 second to allow the campaign to be created
      await new Promise((resolve) => setTimeout(resolve, 1000));
      fetchCampaigns?.();
      //navigate(ROUTE_CAMPAIGNS);
    } catch (e) {
      console.error(e);
    }
  };

  // Redirect to the campaign page when campaign is created
  useEffect(() => {
    if (createdCampaign?.id) {
      navigate(`${ROUTE_CAMPAIGNS}/${createdCampaign.id}`);
    }
  }, [createdCampaign]);

  // Estimated leads
  const [estimatedLeadsText, setEstimatedLeadsText] = useState<string | null>(
    null
  );

  const value: CampaignCreateContextProps = {
    activeStep,
    campaignChannel,
    companyTargetRequest,
    focuses,
    prospects,
    canSearchProspects,
    canCreateCampaign,
    isCreatingCampaign,
    isSearchingProspects,
    isCreatingCampaignRequest,
    campaignRequestError,
    suggestedTargetingId,
    estimatedLeadsText,
    setEstimatedLeadsText,
    setActiveStep,
    setCompanyTargetRequest,
    setFocuses,
    setCampaignChannel,
    handleSearchProspects,
    handleCreateCampaign,
  };

  return (
    <CampaignCreateContext.Provider value={value}>
      {children}
    </CampaignCreateContext.Provider>
  );
};

export { CampaignCreateContextProvider, useCampaignCreateContext };
