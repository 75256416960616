import { Box, Button, Link, Typography } from "@mui/material";

import { NextIcon } from "@components/icons";
import { BYOBStage } from "./types";





interface InstructionsProps {
  setStage: (stage: BYOBStage) => void;
}

const GmailInstructionsImap = (
  { setStage }: InstructionsProps
) => {
  const handleReady = () => {
    setStage(BYOBStage.READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH);
  }

  return (
    <>

      <Typography variant="h6">
        2. G-Suite: Enable IMAP
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingLeft: 2, marginTop: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            1.
          </Typography>
          <Typography variant="body1">
            Log into the Gsuite account you want to add, and ensure you are logged out of all other gmail accounts.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            2.
          </Typography>
          <Typography variant="body1">
            Click <Link href="https://mail.google.com/mail/u/0/#settings/fwdandpop" target="_blank" rel="noopener"><Typography component="span" fontWeight="bold">here.</Typography></Link>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            3.
          </Typography>
          <Typography variant="body1">
            If IMAP is already enabled, skip the next step
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            4.
          </Typography>
          <Typography variant="body1">
            In the <Typography component="span" fontWeight="bold">IMAP access</Typography> section, select <Typography component="span" fontWeight="bold">Enable IMAP</Typography> and then click <Typography component="span" fontWeight="bold">Save Changes</Typography>.
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReady}
        endIcon={<NextIcon />}
        sx={{
          marginTop: 2,
        }}
      >
        Next
      </Button>
    </>
  )
}

export default GmailInstructionsImap;