import {
  Box,
  Button,
  IconButton,
  Link,
  Snackbar,
  Typography
} from "@mui/material";

import { GoogleIcon } from "@components/icons";
import { useState } from "react";
import { BYOBStage } from "./types";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';




interface InstructionsProps {
  setStage: (stage: BYOBStage) => void;
}

const GmailInstructionsWhitelistOAuth = (
  { setStage }: InstructionsProps
) => {
  const handleReady = () => {
    setStage(BYOBStage.ACTUALLY_AUTHENTICATE_GOOGLE);
  }

  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const clientId = "418246249517-0hkfel9167cg0kqi135ef01d99vhb1ap.apps.googleusercontent.com";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(clientId);
      setShowCopyAlert(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <Typography variant="h6">
        3. Add Kular to Google Workspace
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingLeft: 2, marginTop: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            1.
          </Typography>
          <Typography variant="body1">
            Go to <Link href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps" target="_blank" rel="noopener">Google Workspace Admin Panel</Link>.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            2.
          </Typography>
          <Typography variant="body1">
            Click <Typography component="span" fontWeight="bold">Configure new app.</Typography>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            3.
          </Typography>
          <Box sx={{ mt: 0, mb: 1 }}>
            <Typography variant="body1" gutterBottom>
              Copy the following app id and paste it in the <Typography component="span" fontWeight="bold">Search for app</Typography> search bar, then click <Typography component="span" fontWeight="bold">Search.</Typography>
            </Typography>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'grey.900',
              borderRadius: 1,
              p: 2,
              mt: 1
            }}>
              <Typography
                component="span"
                sx={{
                  fontFamily: 'monospace',
                  fontSize: '0.9em',
                  color: 'grey.100',
                  flex: 1,
                  mr: 1
                }}
              >
                {clientId}
              </Typography>

              <IconButton
                onClick={handleCopy}
                size="small"
                sx={{
                  color: 'grey.400',
                  '&:hover': {
                    color: 'grey.100'
                  }
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>

            <Snackbar
              open={showCopyAlert}
              autoHideDuration={2000}
              onClose={() => setShowCopyAlert(false)}
              message="Client ID copied to clipboard"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            4.
          </Typography>
          <Typography variant="body1">
            Select the first result with the name <Typography component="span" fontWeight="bold">Kular Inbox Integration</Typography> and click <Typography component="span" fontWeight="bold">Continue.</Typography>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            5.
          </Typography>
          <Typography variant="body1">
            Select <Typography component="span" fontWeight="bold">Trusted</Typography> option and hit <Typography component="span" fontWeight="bold">Continue.</Typography>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="body1" component="span" fontWeight="medium">
            6.
          </Typography>
          <Typography variant="body1">
            Hit <Typography component="span" fontWeight="bold">Finish</Typography> and you should now be able to see <Typography component="span" fontWeight="bold">Kular Inbox Integration</Typography> in the list of configured apps.
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReady}
        endIcon={<GoogleIcon />}
        sx={{
          marginTop: 2,
        }}
      >
        I'm Ready
      </Button>
    </>
  )
}

export default GmailInstructionsWhitelistOAuth;