

import { WarningIcon } from "@components/icons";
import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

type LinkedInProps = {
  linkedinUrl: string | null
  setLinkedinUrl: React.Dispatch<React.SetStateAction<string | null>>
}

const LinkedIn = ({
  linkedinUrl, setLinkedinUrl
}: LinkedInProps) => {
  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={"flex-start"}
        spacing={2}
      >
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          width={300}
        >
          {!linkedinUrl && (
            <WarningIcon color="error" fontSize="small" />
          )}
          <Typography
            color={!linkedinUrl ? "error" : "text.secondary"}
            variant="subtitle1"
          >
            Linkedin
          </Typography>
        </Stack>
        <Box width={400}>
          <TextField
            size="small"
            variant="outlined"
            value={linkedinUrl || ""}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            label="Your Linkedin URL"
            fullWidth
          />
        </Box>
      </Stack>

      <Divider />
    </>
  );
};

export default LinkedIn;
