import { CampaignChannel, Template } from "@/models";
import { AddIcon, DeleteIcon, EditIcon, SendIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useCampaignContext } from "@context/CampaignContext";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";

import { TrackedEventName } from "@/third-party/tracking";
import Tip from "@components/Tip";
import Tracked from "@components/Tracked";
import useExitPrompt from "@hooks/useExitPrompt";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDeleteEmailCampaignTemplate } from "@services/campaigns/deleteEmailCampaignTemplate";
import { useDeleteLinkedinCampaignTemplate } from "@services/campaigns/deleteLinkedinCampaignTemplate";
import { usePatchEmailCampaign } from "@services/campaigns/patchEmailCampaign";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddTemplate from "./AddTemplate";
import EditTemplate from "./EditTemplate";
import TestTemplateDialog from "./TestTemplateDialog";

const CampaignMessaging = () => {
  const { profile } = useAppContext();
  const {
    campaign,
    templates,
    editedTemplateId,
    setEditedTemplateId,
    fetchCampaign,
  } = useCampaignContext();

  // Handle update subject
  const [subject, setSubject] = useState<string | null>(null);

  // Initialise subject
  useEffect(() => {
    setSubject(campaign?.subject || null);
  }, [campaign?.subject]);

  const [patchEmailCampaign, { loading: isPatchingEmailCampaign }] =
    usePatchEmailCampaign();

  const handleSubjectUpdate = () => {
    if (!campaign?.id) return;
    if (!subject) return;

    patchEmailCampaign(
      {
        campaignId: campaign.id,
        subject,
      },
      {
        successMessage: "Subject updated successfully",
      }
    );
  };

  // Test Template
  const [showTestTemplate, setShowTestTemplate] = useState<boolean>(false);

  // Delete template
  const [deleteEmailTemplate, { loading: isDeletingEmailTemplate }] =
    useDeleteEmailCampaignTemplate();

  const [deleteLinkedinTemplate, { loading: isDeletingLinkedinTemplate }] =
    useDeleteLinkedinCampaignTemplate();

  const deleteTemplate = useCallback(
    async (template: Template) => {

      await deleteEmailTemplate({
        templateId: template.id,
      });

      fetchCampaign?.();
    },
    [deleteEmailTemplate]
  );

  const isDeletingTemplate = useMemo(() => {
    return isDeletingEmailTemplate || isDeletingLinkedinTemplate;
  }, [isDeletingEmailTemplate, isDeletingLinkedinTemplate]);

  const isBusy = useMemo(() => {
    return isPatchingEmailCampaign || isDeletingTemplate || isDeletingTemplate;
  }, [isPatchingEmailCampaign, isDeletingTemplate]);

  // Add template
  const [showAddTemplate, setShowAddTemplate] = useState<boolean>(false);
  const [chosenTemplate, setChosenTemplate] = useState<Template>(templates[0]);

  const bottomRef = useRef<null | HTMLButtonElement>(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const topRef = useRef<null | HTMLDivElement>(null);
  const scrollToTop = () => {
    setTimeout(() => {
      topRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 500);
  };

  // Scroll to bottom when add template is show
  // Scroll to top when add template is hidden
  useEffect(() => {
    if (showAddTemplate) {
      scrollToBottom();
    }
  }, [showAddTemplate]);

  useExitPrompt(!!editedTemplateId);

  return (
    <Box ref={topRef}>
      {campaign?.channel === CampaignChannel.EMAIL && (
        <>
          <Stack direction="row" spacing={1} mb={2} alignItems="center">
            <TextField
              id="campaign-subject"
              label="Subject"
              variant="outlined"
              fullWidth
              size="small"
              value={subject || ""}
              disabled={isBusy}
              onChange={(e: any) => setSubject(e.target.value)}
            />
            <LoadingButton
              loading={isPatchingEmailCampaign}
              variant="contained"
              onClick={() => handleSubjectUpdate()}
              disabled={isBusy || subject === campaign?.subject}
            >
              Save
            </LoadingButton>
          </Stack>
        </>
      )}
      <Box mt={2} />
      <Stack direction="column" spacing={0}>
        <Typography variant="subtitle1">Messages</Typography>
        <Typography variant="body2" color="text.secondary">
          Edit, add or delete messages from your campaign. Messages will be sent
          in the order they appear below.
        </Typography>
      </Stack>
      <Box mt={2} />
      <Box
        mt={showAddTemplate ? 2 : 0}
        sx={{
          ".MuiTimeline-root": {
            margin: 0,
            padding: 0,
          },
        }}
      >
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {templates?.map((template, index) => {
            const isEditted = editedTemplateId === template.id;

            return (
              <TimelineItem key={template.id}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "#FAFAFA",
                    }}
                  >
                    <CardHeader
                      title={
                        <Typography color="text.secondary">
                          Message #{index + 1}
                        </Typography>
                      }
                      titleTypographyProps={{ variant: "subtitle1" }}
                      action={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Tracked
                            onClick={{ name: TrackedEventName.TEMPLATE_EDITED }}
                          >
                            <Button
                              disabled={isBusy}
                              startIcon={<EditIcon />}
                              onClick={() => {
                                if (isEditted) {
                                  setEditedTemplateId(null);
                                } else {
                                  setEditedTemplateId(template.id);
                                }
                              }}
                              color={isEditted ? "secondary" : "primary"}
                              variant="text"
                            >
                              Edit
                            </Button>
                          </Tracked>{" "}
                          <Tracked
                            onClick={{
                              name: TrackedEventName.TEST_EMAIL_ATTEMPTED,
                            }}
                          >
                            <Tip title={`Send test email to ${profile?.email}`}>
                              <Button
                                disabled={isBusy}
                                startIcon={<SendIcon />}
                                variant="text"
                                size="small"
                                onClick={() => {
                                  setChosenTemplate(template);
                                  setShowTestTemplate(true);
                                }}
                              >
                                Send Test Email
                              </Button>
                            </Tip>
                          </Tracked>
                          <Tracked
                            onClick={{
                              name: TrackedEventName.TEMPLATE_DELETED,
                            }}
                          >
                            <Tip title={"Permanently delete this message"}>
                              <Button
                                disabled={isBusy}
                                startIcon={<DeleteIcon />}
                                onClick={() => deleteTemplate(template)}
                              >
                                Delete
                              </Button>
                            </Tip>
                          </Tracked>
                        </Stack>
                      }
                    />

                    <CardContent
                      sx={{
                        boxShadow: "none",
                        backgroundColor: isEditted ? "transparent" : "#FAFAFA",
                      }}
                    >
                      <Box px={2}>
                        <EditTemplate
                          template={template}
                          onClose={() => setEditedTemplateId(null)}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
        <TestTemplateDialog
          open={showTestTemplate}
          onClose={() => setShowTestTemplate(false)}
          template={chosenTemplate}
        />
        <Collapse in={showAddTemplate}>
          <Box mt={4} />
          <AddTemplate
            onClose={() => setShowAddTemplate(false)}
            onAdd={() => {
              setShowAddTemplate(false);
              fetchCampaign?.();
            }}
          />
        </Collapse>
        <Box mt={2}>
          <Button
            disabled={isBusy || showAddTemplate}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setShowAddTemplate(!showAddTemplate);
            }}
          >
            Add Message
          </Button>
        </Box>
        <Box ref={bottomRef} />
      </Box>
    </Box>
  );
};

export default CampaignMessaging;
