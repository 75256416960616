

import { Divider, Stack, Typography } from "@mui/material";
import PauseAccountDialogButton from "./PauseAccountDialogButton";

type PauseAccountProps = {
}

const PauseAccount = ({ }: PauseAccountProps) => {

  return (
    <>

      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Typography color="text.secondary" variant="subtitle1">
            Pause Account
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            You can pause your account. This will stop all campaigns.
          </Typography>
        </Stack>
        <PauseAccountDialogButton />
      </Stack>
      <Divider />
    </>
  );
};

export default PauseAccount;