// Conversation Context

import { useGetCampaignMemberLazyQuery } from "@/graphql/generated";
import {
  BLURRED_VISIBILITY_STATES,
  CampaignChannel,
  CampaignMember,
  CampaignMemberCharge,
  CampaignMemberMeeting,
  Company,
  Contact,
  DISPLAYED_VISIBILITY_STATES,
  FREE_VISIBILITY_STATES,
  OutreachMessage,
  Refund,
  VisibilityState,
} from "@/models";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID, getFullName } from "@utils/text";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

type ConversationContextProps = {
  conversation: CampaignMember | null;
  lastReply: OutreachMessage | null;
  isLoadingConversation: boolean;
  isBlurred: boolean;
  visibility?: VisibilityState;
  isVisible?: boolean;
  isUnread?: boolean;
  isFree?: boolean;
  invoiceUrl?: string | null;
  charge?: CampaignMemberCharge | null;
  messages?: Array<OutreachMessage> | null;
  contactFullName?: string | null;
  lastMessage?: OutreachMessage | null;
  campaignId?: UUID | null;
  campaignMemberId?: UUID | null;
  refund: Refund | null;
  campaignMemberMeetingId?: UUID | null;
  meeting: CampaignMemberMeeting | null;
  contact: Contact | null;
  company: Company | null;
  deletedTime?: Date | null;
  fetchConversation?: () => void;
};

const initialValues: ConversationContextProps = {
  conversation: null,
  lastReply: null,
  isLoadingConversation: false,
  isBlurred: false,
  visibility: undefined,
  isVisible: false,
  isUnread: false,
  isFree: false,
  invoiceUrl: null,
  charge: null,
  messages: null,
  contactFullName: null,
  lastMessage: null,
  campaignId: null,
  campaignMemberId: null,
  refund: null,
  campaignMemberMeetingId: null,
  meeting: null,
  contact: null,
  company: null,
  deletedTime: null,
  fetchConversation: () => { },
};

const ConversationContext =
  createContext<ConversationContextProps>(initialValues);

export const ConversationProvider = ({
  children,
  campaignMemberId,
}: {
  children: React.ReactNode;
  campaignMemberId: UUID;
}) => {
  const [state, setState] = useState<ConversationContextProps>(initialValues);

  const [
    getCampaignMember,
    {
      data: conversationMember,
      loading: isLoadingConversationMembers,
      refetch: fetchConversation,
    },
  ] = useGetCampaignMemberLazyQuery();

  useEffect(() => {
    getCampaignMember({
      variables: {
        campaignMemberId,
      },
    });
  }, [campaignMemberId]);

  const conversation: CampaignMember = useMemo(() => {
    return normalizeGraphqlResult(conversationMember)?.campaignMember;
  }, [conversationMember]);

  const campaignId = useMemo(
    () => conversation?.campaignId,
    [conversation?.campaignId]
  );
  const messages: Array<OutreachMessage> | null = useMemo(() => {
    if (!conversation) {
      return [];
    }

    let emailMessages = conversation.emailMessages?.filter((message) => !message.deletedTime);
    let linkedinMessages = conversation.linkedinMessages?.filter((message) => !message.deletedTime);

    const messages = [
      ...(linkedinMessages?.map((message: OutreachMessage) => ({
        ...message,
        campaignChannel: CampaignChannel.LINKEDIN,
      })) || []),
      ...(emailMessages?.map((message: OutreachMessage) => ({
        ...message,
        campaignChannel: CampaignChannel.EMAIL,
      })) || []),
    ];

    messages.sort(
      (a, b) =>
        (a.sendAt || a.sentTime || new Date()).getTime() -
        (b.sendAt || b.sentTime || new Date()).getTime()
    );
    return messages;
  }, [conversation]);

  useEffect(() => {
    if (messages) {
      const lawyerReplies = messages.filter((message) => !message.isLawyer);
      const lastReply = lawyerReplies[lawyerReplies.length - 1];

      setState({
        ...state,
        messages,
        lastReply,
      });
    }
  }, [messages]);

  // Visibility
  const visibility = useMemo(() => {
    return conversation?.visibility;
  }, [conversation?.visibility]);

  // Some settings
  const isVisible = useMemo(() => {
    return visibility
      ? !!(
        isLoadingConversationMembers ||
        (visibility && DISPLAYED_VISIBILITY_STATES.includes(visibility))
      )
      : false;
  }, [visibility, isLoadingConversationMembers]);

  const isBlurred = useMemo(() => {
    return BLURRED_VISIBILITY_STATES.includes(visibility);
  }, [visibility]);

  const isUnread = useMemo(() => {
    return !state.lastReply?.lawyerViewedTime;
  }, [state.lastReply?.lawyerViewedTime]);

  const isFree = useMemo(() => {
    return visibility
      ? FREE_VISIBILITY_STATES.includes(visibility) ||
      visibility.includes("free")
      : false;
  }, [visibility]);

  const charge = useMemo(() => {
    return conversation?.charges?.[0] || null;
  }, [conversation]);

  const lastMessage = useMemo(() => {
    return messages?.[messages?.length - 1];
  }, [messages]);

  const refund = useMemo(() => {
    return conversation?.charges?.[0]?.refunds?.[0] || null;
  }, [conversation]);

  // Meetings
  const meeting = useMemo(() => {
    return conversation?.meetings?.[0];
  }, [conversation]);

  const campaignMemberMeetingId = useMemo(() => {
    return meeting?.id;
  }, [conversation]);

  // Contact, company
  const contact = useMemo(() => {
    return conversation?.contact || null;
  }, [conversation]);

  const company = useMemo(() => {
    return contact?.company || null;
  }, [contact]);

  const contactFullName = getFullName(contact?.firstName, contact?.lastName);

  return (
    <ConversationContext.Provider
      value={{
        ...state,
        isBlurred,
        isLoadingConversation: isLoadingConversationMembers,
        visibility: visibility ?? undefined,
        isVisible,
        isUnread,
        isFree,
        invoiceUrl: charge?.stripeInvoiceUrl || null,
        charge,
        contactFullName,
        messages,
        lastMessage,
        campaignMemberId,
        campaignId,
        refund,
        campaignMemberMeetingId,
        company,
        contact,
        conversation,
        fetchConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversationContext = () => useContext(ConversationContext);
