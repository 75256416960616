import { EmailIcon, LinkedinIcon } from "@components/icons";

import { CampaignChannel } from "@/models";
import { useAppContext } from "@context/AppContext";
import { useCampaignCreateContext } from "@context/CampaignCreateContext";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

const StepCampaignChannel = () => {

  const { setCampaignChannel } = useCampaignCreateContext();

  return (
    <Box>
      <RadioGroup
        aria-labelledby="campaign-type"
        defaultValue={CampaignChannel.EMAIL}
        name="radio-buttons-group"
        onChange={(e) => setCampaignChannel(e.target.value as CampaignChannel)}
      >
        <FormControlLabel
          value={CampaignChannel.EMAIL}
          control={<Radio size="small" />}
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <EmailIcon fontSize="medium" color="primary" />
              <Typography variant="body1">Email campaign</Typography>
            </Stack>
          }
        />
        <FormControlLabel
          value={CampaignChannel.LINKEDIN}
          control={<Radio size="small" />}
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <LinkedinIcon
                fontSize="medium"
                color="primary"
              />
              <Typography variant="body1">LinkedIn campaign</Typography>
            </Stack>
          }
        />
      </RadioGroup>
    </Box>
  );
};

export default StepCampaignChannel;
