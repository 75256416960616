import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";

import { BackIcon } from "@components/icons";
import ActuallyAuthenticateEmail from "./ActuallyAuthenticateEmail";
import ChooseEmailProvider from "./ChooseEmailProvider";
import GmailInstructionsImap from "./GmailInstructionsImap";
import GmailInstructionsWhitelistOAuth from "./GmailInstructionsWhitelistOAuth";
import GodaddyInstructions from "./GodaddyInstructions";
import OutlookInstructions from "./OutlookInstructions";
import { BYOBStage } from "./types";


interface DialogProps {
  open: boolean;
  textContent?: string;
  onClose: () => void;
  email: string;
}

const GO_BACK_MAP: Record<BYOBStage, BYOBStage> = {
  [BYOBStage.ACTUALLY_AUTHENTICATE_GOOGLE]: BYOBStage.READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH,
  [BYOBStage.READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH]: BYOBStage.READ_GOOGLE_INSTRUCTIONS_IMAP,
  [BYOBStage.READ_GOOGLE_INSTRUCTIONS_IMAP]: BYOBStage.CHOOSE_EMAIL_PROVIDER,


  [BYOBStage.ACTUALLY_AUTHENTICATE_OUTLOOK]: BYOBStage.READ_OUTLOOK_INSTRUCTIONS,
  [BYOBStage.READ_OUTLOOK_INSTRUCTIONS]: BYOBStage.CHOOSE_EMAIL_PROVIDER,

  [BYOBStage.ACTUALLY_AUTHENTICATE_GODADDY]: BYOBStage.READ_GODADDY_INSTRUCTIONS,
  [BYOBStage.READ_GODADDY_INSTRUCTIONS]: BYOBStage.CHOOSE_EMAIL_PROVIDER,

  [BYOBStage.CHOOSE_EMAIL_PROVIDER]: BYOBStage.CHOOSE_EMAIL_PROVIDER,

}

const BYOBDialog = ({
  open,
  textContent,
  email,
  onClose,
}: DialogProps) => {
  const [stage, setStage] = useState<BYOBStage>(BYOBStage.CHOOSE_EMAIL_PROVIDER);

  const goBack = () => {
    if (stage === BYOBStage.CHOOSE_EMAIL_PROVIDER) {
      onClose();
      return;
    }

    setStage(GO_BACK_MAP[stage]);

  }

  const handleOnClose = () => {
    setStage(BYOBStage.CHOOSE_EMAIL_PROVIDER);
    onClose();
  }

  useEffect(() => {
    console.log({ stage })
  }, [stage])


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOnClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            color="primary.main"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              color="primary.main"
              alignItems="center"
              justifyContent="flex-start"
            >

              <IconButton
                aria-label="close"
                onClick={goBack}
                sx={{
                  margin: 0,
                  padding: 0,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <BackIcon />
              </IconButton>
              <Typography
                variant="h5"
                color="text.primary"
                gutterBottom={false}
              >
                Kular Inbox Integration
              </Typography>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ marginTop: 2 }} />
        </DialogTitle>
        <DialogContent>

          {stage === BYOBStage.CHOOSE_EMAIL_PROVIDER ? (
            <ChooseEmailProvider setStage={setStage} />
          ) : stage === BYOBStage.READ_OUTLOOK_INSTRUCTIONS ? (
            <OutlookInstructions setStage={setStage} />
          ) : stage === BYOBStage.READ_GODADDY_INSTRUCTIONS ? (
            <GodaddyInstructions setStage={setStage} />
          ) : stage === BYOBStage.READ_GOOGLE_INSTRUCTIONS_IMAP ? (
            <GmailInstructionsImap setStage={setStage} />
          ) : stage === BYOBStage.READ_GOOGLE_INSTRUCTIONS_WHITELIST_OAUTH ? (
            <GmailInstructionsWhitelistOAuth setStage={setStage} />

          ) : stage === BYOBStage.ACTUALLY_AUTHENTICATE_GOOGLE || stage === BYOBStage.ACTUALLY_AUTHENTICATE_OUTLOOK || stage === BYOBStage.ACTUALLY_AUTHENTICATE_GODADDY ? (
            <ActuallyAuthenticateEmail email={email} />
          ) : <></>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BYOBDialog;