import { LinkedinIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { useGetLinkedinEnabledFor } from "@services/linkedin/getEnabledForLinkedin";
import { useGetLinkedinHasProfile } from "@services/linkedin/getLinkedinHasProfile";
import { useEffect } from "react";
import LinkedinControl from './LinkedinControl';



type AuthenticateLinkedinProps = {
}

const AuthenticateLinkedin = ({ }: AuthenticateLinkedinProps) => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut, isCalendarConnected, hasCalendarProfiles, calendarProfiles } =
    useAppContext();


  const [getLinkedinEnabledFor, { data: isLinkedinEnabled }] = useGetLinkedinEnabledFor();
  const [getLinkedinHasProfile, { data: isLinkedinHasProfile }] = useGetLinkedinHasProfile();

  useEffect(() => {
    if (lawyerId) {
      getLinkedinEnabledFor({ lawyerId });
    }
  }, [lawyerId, getLinkedinEnabledFor]);

  useEffect(() => {
    if (lawyerId) {
      getLinkedinHasProfile({ lawyerId });
    }
  }, [lawyerId, getLinkedinHasProfile]);

  return (
    <>

      {isLinkedinEnabled && (
        <>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            spacing={2}
          >
            <Stack direction={"column"} spacing={1} width={300}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <LinkedinIcon color="primary" />
                <Typography color="text.secondary" variant="subtitle1">
                  Authenticate LinkedIn
                </Typography>
              </Stack>
              <Typography variant="caption" color={"text.secondary"}>
                Authenticate your LinkedIn profile for usage with Kular campaigns.
              </Typography>
            </Stack>

            {isLinkedinHasProfile ? (
              <Box sx={{ color: 'success.main', display: 'flex', alignItems: 'center', gap: 1 }}>
                <CheckCircleIcon />
                <Typography>LinkedIn Successfully Connected</Typography>
              </Box>
            ) : (
              <LinkedinControl lawyerId={lawyerId || ""} />
            )}
          </Stack>
          <Divider />
        </>
      )}
    </>
  );
};

export default AuthenticateLinkedin;
